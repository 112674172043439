<template>
  <div>
    <!--begin::Row-->
    <!-- <div class="row">
        <div class="col-lg-12"> -->
    <div class="card card-custom card-stretch gutter-b">
      <!--begin::Header-->
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Pengguna</h3>
        </div>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body pt-0">
        <!--begin::Item-->
        <div class="mb-6">
          <!--begin::Content-->
          <div>
            <br />
            <button type="button" class="btn btn-primary" @click="goTambah()">
              <i class="flaticon2-plus"></i>Tambah
            </button>
            <br /><br />
            <div>
              <div style="overflow-x: auto; overflow-y: hidden">
                <table
                  id="example"
                  class="table table-striped table-bordered"
                  style="width: 100%"
                >
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Nama</th>
                      <th>Id Role</th>
                      <th>Role</th>
                      <th>Username</th>
                      <th>Email</th>
                      <th>Id PTSP</th>
                      <th>Aksi</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="row in users" :key="row.id">
                      <td></td>
                      <td>{{ row.name }}</td>
                      <td>{{ row.id_role }}</td>
                      <td>{{ row.name_role }}</td>
                      <td>{{ row.username }}</td>
                      <td>{{ row.email }}</td>
                      <td>{{ row.id_ptsp }}</td>
                      <td>
                        <div style="width: 120px">
                          <b-button
                            @click="$router.push('/profil-user/' + row.id)"
                            size="sm"
                            variant="success"
                            ><i class="flaticon-eye"></i
                          ></b-button>
                          &nbsp;
                          <button
                            type="button"
                            class="btn btn-sm btn-warning"
                            @click="goUbah(row.id)"
                          >
                            <i class="flaticon-edit" aria-hidden="true"></i
                            >Ubah</button
                          >&nbsp;
                          <b-button
                            :id="'popoverDelete_' + row.id"
                            size="sm"
                            variant="danger"
                            ><i class="flaticon2-trash"></i> Hapus</b-button
                          >
                          <b-popover
                            class="popover-danger"
                            triggers="focus"
                            :target="'popoverDelete_' + row.id"
                            :show.sync="popovers[row.id]"
                            placement="top"
                          >
                            <template slot="title">
                              <div class="px-5">Ingin menghapus?</div>
                            </template>
                            <div class="px-5 d-flex justify-content-center">
                              <b-button-toolbar>
                                <b-button
                                  variant="success"
                                  size="xs"
                                  @click="deleteuser(row.id)"
                                >
                                  Ya
                                </b-button>
                                <b-button
                                  variant="danger"
                                  size="xs"
                                  @click="closePopover(row.id)"
                                >
                                  Tidak
                                </b-button>
                              </b-button-toolbar>
                            </div>
                          </b-popover>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!--end::Content-->
        </div>
        <!--end::Item-->
      </div>
      <!--end: Card Body-->
    </div>

    <!-- <b-modal
      id="modal-tambah"
      ref="modal"
      size="lg"
      scrollable
      title="Tambah User"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form ref="form" @submit.stop.prevent="tambahuser">
        <div class="form-group row">
          <label class="col-lg-4 col-form-label">Nama</label>
          <div class="col-lg-8">
            <input type="text" v-model="name" class="form-control" />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-lg-4 col-form-label">Email</label>
          <div class="col-lg-8">
            <input type="text" v-model="email" class="form-control" />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-lg-4 col-form-label">No HP</label>
          <div class="col-lg-8">
            <input type="text" v-model="no_hp" class="form-control" />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-lg-4 col-form-label">Username</label>
          <div class="col-lg-8">
            <input type="text" v-model="username" class="form-control" />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-lg-4 col-form-label">Password</label>
          <div class="col-lg-8">
            <input type="password" v-model="password" class="form-control" />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4">Role</label>
          <div class="col-8">
            <s-select label="name" v-model="selectedrole" :options="roles" />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-lg-4 col-form-label">Alamat</label>
          <div class="col-lg-8">
            <textarea type="text" v-model="alamat" class="form-control" />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-lg-4 col-form-label">Bidang Ilmu</label>
          <div class="col-lg-8">
            <input type="text" v-model="bidang_ilmu" class="form-control" />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-lg-4 col-form-label">Pendidikan Terakhir</label>
          <div class="col-lg-8">
            <input
              type="text"
              v-model="pendidikan_terakhir"
              class="form-control"
            />
          </div>
        </div>
        <template v-if="selectedrole.id === 'ptsp_provinsi' || selectedrole.id === 'ptsp_kabupaten' || selectedrole.id === 'ptsp_kota' || selectedrole.id === 'ppb_kl' || selectedrole.id === 'ppb_kl'">
        <div class="form-group row">
          <label class="col-lg-4 col-form-label">Tempat/Tanggal Lahir</label>
          <div class="col-lg-8">
            <div class="row">
              <div class="col-xl-6">
                <div class="form-group">
                  <input
                    type="text"
                    v-model="tempat_lahir"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="col-xl-6">
                <div class="form-group">
                  <b-form-datepicker
                    v-model="tanggal_lahir"
                    id="example-datepicker"
                    class="mb-2"
                  ></b-form-datepicker>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-lg-4 col-form-label">Upload File Ijazah</label>
          <div class="col-lg-8">
            <b-form-file v-model="ijazah" class="mt-3" plain></b-form-file>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-lg-4 col-form-label">ID PTSP / IDKL</label>
          <div class="col-lg-8">
            <input type="text" v-model="id_ptsp" class="form-control" />
          </div>
        </div>
      </form>
    </b-modal> -->

    <div
      class="modal fade"
      id="edituser"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLongTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              Ubah Data User
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-lg-4 col-form-label">Nama</label>
              <div class="col-lg-8">
                <input
                  type="text"
                  v-model="getuser.name"
                  class="form-control"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-lg-4 col-form-label">Email</label>
              <div class="col-lg-8">
                <input
                  type="text"
                  v-model="getuser.email_baru"
                  class="form-control"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-lg-4 col-form-label">No HP</label>
              <div class="col-lg-8">
                <input
                  type="text"
                  v-model="getuser.no_hp"
                  class="form-control"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-lg-4 col-form-label">Username</label>
              <div class="col-lg-8">
                <input
                  type="text"
                  v-model="getuser.username_baru"
                  class="form-control"
                />
              </div>
            </div>
            <!-- <div class="form-group row">
                        <label class="col-4">Role</label>
                        <div class="col-8">
                            <b-form-select disabled v-model="getuser.id_role">
                                <option
                                    v-for="(datarole, k) in roles"
                                    :key="k"
                                    :value="datarole.id"
                                >
                                    {{ datarole.name }}
                                </option>
                            </b-form-select>
                        </div>
                    </div> -->
            <div class="form-group row">
              <label class="col-lg-4 col-form-label">Alamat</label>
              <div class="col-lg-8">
                <textarea
                  type="text"
                  v-model="getuser.alamat"
                  class="form-control"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-lg-4 col-form-label">Bidang Ilmu</label>
              <div class="col-lg-8">
                <input
                  type="text"
                  v-model="getuser.bidang_ilmu"
                  class="form-control"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-lg-4 col-form-label">Pendidikan Terakhir</label>
              <div class="col-lg-8">
                <input
                  type="text"
                  v-model="getuser.pendidikan_terakhir"
                  class="form-control"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-lg-4 col-form-label"
                >Tempat/Tanggal Lahir</label
              >
              <div class="col-lg-8">
                <div class="row">
                  <div class="col-xl-6">
                    <!--begin::Input-->
                    <div class="form-group">
                      <input
                        type="text"
                        v-model="getuser.tempat_lahir"
                        class="form-control"
                      />
                    </div>
                    <!--end::Input-->
                  </div>
                  <div class="col-xl-6">
                    <!--begin::Input-->
                    <div class="form-group">
                      <b-form-datepicker
                        v-model="getuser.tanggal_lahir"
                        id="example-datepicker"
                        class="mb-2"
                      ></b-form-datepicker>
                    </div>
                    <!--end::Input-->
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="form-group row">
                        <label class="col-lg-4 col-form-label">ID PTSP / IDKL</label>
                                <div class="col-lg-8">
                                    <input type="text" v-model="getuser.id_ptsp" class="form-control"/>
                                </div>
                    </div>   -->
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Tutup
            </button>
            <button
              type="button"
              @click="edituser(getuser.id)"
              style="
                background-color: #030a8c;
                border-color: #030a8c;
                color: white;
              "
              data-dismiss="modal"
              class="btn btn-primary"
            >
              Simpan
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- </div>
  </div> -->
</template>
<style>
div.messages {
  /* background-color: lightblue; */
  /* width: 110px; */
  height: 500px;
  overflow: auto;
}
</style>
<script>
import Vue from "vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from "axios";
import vSelect from "vue-select";

Vue.component("s-select", vSelect);

export default {
  name: "User",
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      url: localStorage.getItem("baseapi"),
      token: localStorage.getItem("id_token"),
      test: "hai",
      popovers: {},
      users: [],
      getuser: [],
      roles: [],
      provinsi: [],
      form: {
        namaa: null,
      },
    };
  },
  components: {
    vSelect,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Users" }]);
    this.getdata();
  },
  methods: {
    closePopover(id) {
      this.popovers[id] = false;
    },
    getdata() {
      if (this.user.id_role === "super_user") {
        $("#example").DataTable().destroy();
        this.loaddata();
      } else {
        this.$router.push({ name: "dashboard" });
      }
    },
    initDatatable() {
      setTimeout(() => {
        var t = $("#example").DataTable({
          pagingType: "full_numbers",
          language: {
            info: "Menampilkan _START_ sampai _END_ dari _TOTAL_ data",
            paginate: {
              first: "Awal",
              last: "Akhir",
              next: "Selanjutnya",
              previous: "Sebelumnya",
            },
          },
          order: [[0, "desc"]],
          responsive: true,
          destroy: true,
          retrieve: true,
          autoFill: true,
          colReorder: true,
        });
        t.on("order.dt search.dt", function () {
          t.column(0, { search: "applied", order: "applied" })
            .nodes()
            .each(function (cell, i) {
              cell.innerHTML = i + 1;
            });
        }).draw();
      }, 300);
    },
    loaddata() {
      axios
        .get(this.url + "/users", {
          headers: {
            xth: this.token,
          },
        })
        .then((response) => {
          this.users = response.data.data;
          this.initDatatable();
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
    edituser(id) {
      if (this.getuser.email != this.getuser.email_baru) {
        axios
          .put(
            this.url + `/users/${id}`,
            {
              // idptsp,
              alamat: this.getuser.alamat,
              bidang_ilmu: this.getuser.bidang_ilmu,
              email: this.getuser.email_baru,
              name: this.getuser.name,
              no_hp: this.getuser.no_hp,
              pendidikan_terakhir: this.getuser.pendidikan_terakhir,
            },
            {
              headers: {
                xth: this.token,
              },
            }
          )
          .then((res) => {
            this.$bvToast.toast("Berhasil Diedit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            this.getdata();
            return res;
          })
          .catch((err) => {
            console.log(err);
            this.$bvToast.toast(err.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return err;
          });
        // Push the name to submitted names
        // this.submittedNames.push(this.name)
        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide("edituser");
        });
      } else if (this.getuser.username != this.getuser.username_baru) {
        axios
          .put(
            this.url + `/users/${id}`,
            {
              // idptsp,
              alamat: this.getuser.alamat,
              bidang_ilmu: this.getuser.bidang_ilmu,
              name: this.getuser.name,
              no_hp: this.getuser.no_hp,
              pendidikan_terakhir: this.getuser.pendidikan_terakhir,
              username: this.getuser.username_baru,
            },
            {
              headers: {
                xth: this.token,
              },
            }
          )
          .then((res) => {
            this.$bvToast.toast("Berhasil Diedit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            this.getdata();
            return res;
          })
          .catch((err) => {
            console.log(err);
            this.$bvToast.toast(err.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return err;
          });
        // Push the name to submitted names
        // this.submittedNames.push(this.name)
        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide("edituser");
        });
      } else {
        axios
          .put(
            this.url + `/users/${id}`,
            {
              // idptsp,
              alamat: this.getuser.alamat,
              bidang_ilmu: this.getuser.bidang_ilmu,
              name: this.getuser.name,
              no_hp: this.getuser.no_hp,
              pendidikan_terakhir: this.getuser.pendidikan_terakhir,
            },
            {
              headers: {
                xth: this.token,
              },
            }
          )
          .then((res) => {
            this.$bvToast.toast("Berhasil Diedit", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            this.getdata();
            return res;
          })
          .catch((err) => {
            console.log(err);
            this.$bvToast.toast(err.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return err;
          });
        // Push the name to submitted names
        // this.submittedNames.push(this.name)
        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide("edituser");
        });
      }
    },
    getdetail(id) {
      axios
        .get(this.url + `/users/${id}`, {
          headers: {
            xth: this.token,
          },
        })
        .then((response) => {
          this.getuser = response.data.data;
          this.getuser["email_baru"] = this.getuser.email;
          this.getuser["username_baru"] = this.getuser.username;
        });
    },
    getrole() {
      axios
        .get(this.url + "/role", {
          headers: {
            xth: this.token,
          },
        })
        .then((response) => {
          this.roles = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
    deleteuser(id) {
      axios
        .delete(this.url + `/users/${id}`, {
          headers: {
            "Content-type": "application/json",
            xth: this.token,
          },
        })
        .then((res) => {
          this.$bvToast.toast("Berhasil Dihapus", {
            title: `Success `,
            variant: `success`,
            solid: true,
          });
          console.log(res);
          this.getdata();
          return res;
        })
        .catch((err) => {
          console.log(err);
          this.$bvToast.toast("Gagal Dihapus", {
            title: `Failed `,
            variant: `danger`,
            solid: true,
          });
          return err;
        });
    },
    getprovinsi() {
      axios
        .get(this.url + "/provinsi", {
          headers: {
            xth: this.token,
          },
        })
        .then((response) => {
          this.provinsi = response.data;
        });
    },
    goTambah() {
      this.$router.push("/tambahPengguna");
    },
    goUbah(id) {
      this.$router.push("/ubahPengguna/" + id);
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },
  },
  created() {
    //   this.getuser(this.user)
    // this.getlocaldata()
  },
};
</script>
